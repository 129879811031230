<template>
  <v-sheet
      v-if="!activator"
      :set="hover = false"
           class="wsRoundedLight"
           :class="[{pointer : !disabled}]"
           @dragover.prevent="dropzoneHover=true"
           @dragleave.prevent="dropzoneHover=false"
           @drop.prevent="drop"
           style="transition: all 0.3s ease; position: relative"
           :style="!src && !noBorder ? `border : 2px dashed ${wsACCENT}` : ''"
           :color="!src ? (!disabled ? wsLIGHTCARD : disabledColor) : 'transparent' "
           :min-height="height || '200px'"
           :height="height"
           @click="selectFile(true)">

    <input style="display: none" :id="'videoInput_' + uuid" type="file"  @change="fileBrowsed">

    <v-sheet :color="dropzoneHover ? 'rgba(47,47,47,0.15)' : '#00000000' "
             style="position:absolute; width: 100%; height: 100% !important; transition: all 0.3s ease"
             class=" d-flex align-center justify-center wsRoundedLight ">

      <div class="text-center " style="width: 100%" v-if="!src">
        <v-fade-transition mode="out-in">

          <div v-if="!uploading">
            <slot :selectFile="selectFile" >
              <div v-if="!browser">
                <v-icon :color="wsACCENT" x-large>mdi-tray-arrow-down</v-icon>
                <h5 :style="`color : ${wsACCENT}`" v-if="!customText" >{{ $t('uploader.choose_file') }}</h5>
              </div>
              <div v-else>

                <ft-select
                    :disabled="disabled"
                    @input="$event === 'desktop'  ?  selectFile() : openStorage++"
                    @click.stop
                    :items="[{ text : $t('Choose') , value : 'desktop' } , { text : $t('ChooseFromStorage') , value : 'storage' }]">
                  <v-btn :disabled="disabled" class="noCaps" :color="!disabled ? wsACCENT : 'grey darken-1'"  block text >
                    <v-icon class="mr-2"  :color="!disabled ? wsATTENTION : 'grey'">mdi-file-search</v-icon>
                    {{ $t('Choose') }}
                    <v-icon :color="!disabled ? wsACCENT : 'grey'">mdi-menu-down</v-icon>
                  </v-btn>
                </ft-select>
                <h5 :style="`color : ${!disabled ? wsACCENT : 'grey'}`" v-if="!customText" >{{ $t('uploader.choose_file') }}</h5>


              </div>
            </slot>
          </div>

          <div v-if="uploading" >
            <v-progress-circular size="24" :color="wsACCENT" indeterminate  />
            <h5 :style="`color : ${wsACCENT}`" class="text-center my-3">{{ $t('Uploading') }}... </h5>
            <div class="px-16" style="width: 100%" >
              <h5 class="mb-1"> {{ progres }} % </h5>
              <v-progress-linear height="10" rounded :color="wsACCENT" :value="progres" />

              <v-expand-transition>
                <div v-if="estimatedTimeSeconds">
                  <h6 class="pt-2 font-weight-medium"> {{ $t('UploadEstimatedTime') }} {{ estimatedTimeSecondsFormatted }} ({{ uploadSpeedFormatted }}) </h6>
                </div>
              </v-expand-transition>


            </div>
          </div>

        </v-fade-transition>
      </div>

    </v-sheet>

    <ws-storage-file-picker
        @click.stop=""
        @input="$emit('success',$event)"
        no-activator
        :public="this.public"
        :image="image"
        :video="video"
        :trigger="openStorage"
        :formats="formats"

    />
  </v-sheet>
  <span v-else>
    <input style="display: none" :id="'videoInput_' + uuid" type="file"  @change="fileBrowsed">
    <slot name="activator" :select="selectFile" :loading="uploading">
      <v-btn @click="selectFile" :color="wsACCENT" icon >
        <v-icon> {{  activatorIcon  }} </v-icon>
      </v-btn>
    </slot>
  </span>





</template>

<script>

// import axios from 'axios';
import {mapActions, mapState} from "vuex";

export default {
  name: "wsFileUploader",
  props : {
    src : {
      type : String,
      default : ''
    },
    type : {
      type : String,
      default : ''
    },
    customText : {
      type : Boolean,
      default : false,
    },
    disabledColor : {
      type : String,
      default : 'grey lighten-2'
    },
    superadmin : {
      type : Boolean,
      default : false,
    },
    noEntity : {
      type : Boolean,
      default : false,
    },
    public : {
      type : Boolean,
      default : false,
    },
    image : {
      type : Boolean,
      default : false,
    },
    disabled : {
      type : Boolean,
      default : false,
    },
    video : {
      type : Boolean,
      default : false,
    },
    audio : {
      type : Boolean,
      default : false,
    },
    lang : {
      type : String,
      default : ''
    },
    formats : {
      type : Array,
      default() { return [] }
    },
    isPublic : {
      type : Boolean,
      default : false
    },
    disableFullClick : {
      type : Boolean,
      default : false
    },
    convertVideo : {
      type : Boolean,
      default : false
    },
    videoQualities : {
      type : Array,
      default() { return [] }
    },
    browser : {
      type : Boolean,
      default : false,
    },
    activator : {
      type : Boolean,
      default : false,
    },
    activatorIcon : {
      type : String,
      default : 'mdi-paperclip',
    },
    height : {
      type : String
    },
    noBorder : {
      type : Boolean,
      default : false,
    },
    xls : {
      type : Boolean,
      default : false,
    },
    requestData : {
      type : Object,
      default : null
    },
    maxSize : {},
    folder : {
      type : String,
      default : null
    }


  },
  data() {
    return {
      openStorage : 0,
      dropzoneHover : false,
      signedUrl : '',
      uploading : false,
      progres : 0,
      file : null,
      fullPath : '',
      fileName : '',
      uuid : '1',


      currentChunk : 0,
      chunksTotal : 1,

      timeUploadStart : null,
      timePassed : null,
      estimatedTimeSeconds: null,
      uploadedTotal: 0,
      uploadSpeed: 0,
      estimatedTimeArray : []

    }
  },
  computed : {
    ...mapState( 'dashboard', [  'UPLOAD_LOADING'  ]),
    uploadSpeedFormatted() {
      let speed = this.uploadSpeed
      let type = ' kb/s'
      if (speed > 1000 ) {
        speed = Math.round( this.uploadSpeed/1000*10 )/10
        type = 'mb/s'
      }
      return speed + type
    },
    estimatedTimeSecondsFormatted() {
      let time = Math.round(this.estimatedTimeSeconds)
      let type = '_seconds'
      if (time > 60 ) {
        time = Math.round( this.uploadSpeed/60 )
        type = '_minutes'
      }
      if (time > 60 ) {
        time = Math.round( this.uploadSpeed/60 )
        type = '_hours'
      }
      return this.$tc(type,time)
    }
  },
  watch : {
    id() {
      if (this.id && this.file) {
        this.upload()
      }
    },
    uploading(value) {
      this.$emit('loading' , value)
    },
    progres(value) {
      this.$emit('loading-progress' , value)
    }
  },
  methods : {
    ...mapActions('upload', [ 'UPLOAD_GET_SIGNED_URL', 'UPLOAD_GET_SIGNED_URL_SUPERADMIN','ADD_BUSINESS_USER_FILE' , 'ADD_SUPERADMIN_USER_FILE']),
    ...mapActions('ajax', [ 'REFRESH_TOKEN']),

    progress(uploadProgress,) {
      if ( uploadProgress.loaded !== 1) {
        this.uploading = true

        if ( this.timeUploadStart ) {
          this.timePassed = (new Date() - this.timeUploadStart);

          let uploadSize = (uploadProgress.loaded - this.uploadedTotal)
          this.uploadSpeed = Math.round(uploadSize/this.timePassed);

          let sizeLeft = Math.round(this.file.size -  ( (this.currentChunk * 50000403 ) + uploadProgress.loaded)  )
          let estimatedTime = 0



          if ( this.uploadSpeed > 0 && sizeLeft > 0 ) {
            estimatedTime =  Math.round(sizeLeft/this.uploadSpeed/1000)
            if ( this.estimatedTimeArray.length < 20 ) {
              this.estimatedTimeArray.push(estimatedTime)
            } else {

              let sum = 0
              this.estimatedTimeArray.forEach(time => { sum += time })
              this.estimatedTimeSeconds = Math.round( sum/20*10  ) /10
              this.estimatedTimeArray.splice(0,1)
              this.estimatedTimeArray.push(estimatedTime)
            }

          }

        }


        let chunksPercent = (this.currentChunk ) / this.chunksTotal
        let oneChunkPercents = 1/this.chunksTotal
        let currentChunkPercent =  oneChunkPercents * (uploadProgress.loaded/uploadProgress.total)
        this.progres = Math.round((chunksPercent + currentChunkPercent) * 100)



        this.timeUploadStart = new Date();
        if ( uploadProgress.loaded !== uploadProgress.total ) {
          this.uploadedTotal = uploadProgress.loaded
        } else {
          this.uploadedTotal = 0
        }




      } else {
        this.uploading = false
      }

    },

    customProgress(uploadProgress) {
      if ( uploadProgress.loaded !== 1) {
        this.uploading = true
        this.progres = uploadProgress.loaded * 100
      } else {
        this.uploading = false
      }
    },

    selectFile(allElementClick = false) {
      if ( this.disabled || !this.uuid ) {
        return
      }

      if ( allElementClick && this.disableFullClick) {
        return
      }
      document.getElementById('videoInput_' + this.uuid).click()
    },

    createChunks(file,cSize)  {
      cSize = cSize * 1000 * 1000
      let startPointer = 0;
      let endPointer = file.size;
      let chunks = [];
      while(startPointer<endPointer){
        let newStartPointer = startPointer+cSize;
        chunks.push(file.slice(startPointer,newStartPointer));
        startPointer = newStartPointer;
      }
      return chunks;
    },

    async handleCustomUpload(file ) {

      let { url, route = null , westudyPrivate = false , data = null } = this.requestData

      let form_data = new FormData()
      form_data.append('file' , file)
      form_data.append('mime' , file.type)

      if (data) {
        Object.keys(data).forEach(key => {
          form_data.append(key , data[key] )
        })
      }

      let xhr = new XMLHttpRequest();

      url = route ? (this.$store.state.ajaxRoute + route ) : url

      xhr.open('POST', url );

      if (this.$store.state.business.selectedBusiness.alias && !!route ) {
        xhr.setRequestHeader('Business' , this.$store.state.business.selectedBusiness.alias )
      }
      if ( westudyPrivate ) {
        await this.REFRESH_TOKEN()
        xhr.setRequestHeader('Authorization' , `Bearer ${localStorage.getItem('access_token')}`)
      }

      xhr.onload = async () => {

        data = JSON.parse(xhr.response)
        if ( !data ) {
          this.uploading = false
          this.$emit('error')
          return this.notify(this.$t('Error'))
        }

        if ( !data.result ) {
          this.uploading = false
          this.$emit('error')
          return this.notify(this.$t('Error'))
        }

        this.uploading = false
        this.$emit('success',data.data || {} )

      };

      xhr.onerror =  () => { this.uploading = false }
      xhr.upload.onprogress = this.customProgress

      xhr.send(form_data);

    },

    async upload() {

      this.$emit('upload-start')
      this.uploading = true

      if ( this.maxSize && this.file.size > (parseInt(this.maxSize)*1000) ) {
        this.uploading = false
        return this.notify(this.$t('WrongSizeUploadAttempt') + this.maxSize + 'KB' , 'warning')
      }
      if ( this.formats.length > 0 ) {
        if ( !this.formats.includes(this.file.type.split('/')[1]) ) {
          let formats = this.$t('SupportedFormats') + ': '
          this.formats.forEach((format,index) => {
            formats += format + (index + 1 < this.formats.length ? ', ' : '' )
          } )
          this.uploading = false
          return this.notify(this.$t('WrongFormatUploadAttempt') + '. ' + formats , 'warning')
        }
      }

      if ( this.xls && !this.file.type.includes('spreadsheet')) {
        this.uploading = false
        return this.notify(this.$t('WrongXlsFileUploadAttempt'))
      }

      if ( this.video && !this.file.type.includes('video')) {
        this.uploading = false
        return this.notify(this.$t('WrongVideoFileUploadAttempt'))
      }
      if ( this.image && !this.file.type.includes('image')) {
        this.uploading = false
        return this.notify(this.$t('WrongImageFileUploadAttempt'))
      }
      if ( this.audio && !this.file.type.includes('audio')) {
        this.uploading = false
        return this.notify(this.$t('WrongAudioFileUploadAttempt'))
      }

      let file =  this.file


      // Handle Custom Upload Request
      if ( this.requestData !== null ) {
        this.handleCustomUpload(file )
        return
      }


      let chunks = this.createChunks(file,50)
      let chunkSize = chunks.length

      this.chunksTotal = chunkSize


      let data = {
        name : this.file.name,
        type : this.file.type,
        mime : this.file.type,
        public : this.public || this.isPublic,
        chunks_total : chunkSize,
        size : this.file.size,

      }
      if ( this.convertVideo ) {
        data.qualities = this.videoQualities.length === 0 ? [9999] : this.videoQualities
      }


      let result = !this.superadmin ?  await this.UPLOAD_GET_SIGNED_URL(data) : await this.UPLOAD_GET_SIGNED_URL_SUPERADMIN(data)
      if ( !result ) {
        this.uploading = false
        return this.notify('Error')
      }
      let signed_url = result.url
      this.fileName = result.name
      this.fullPath = result.full_path

      let index = 0;

      let handleUpload = (index) => {

        this.currentChunk = index

        let chunk = chunks[index]
        let form_data = new FormData()
        form_data.append('file_size' , file.size)
        form_data.append('upload_file' , chunk)
        form_data.append('mime' , file.type)

        let xhr = new XMLHttpRequest();
        xhr.open('POST', signed_url);

        xhr.onload = async () => {

          data = JSON.parse(xhr.response)
          if ( !data ) {
            this.uploading = false
            return this.notify(this.$t('Error'))
          }

          if ( !data.result ) {
            this.uploading = false
            return this.notify(this.$t('Error'))
          }
          let data = data.data

          if ( this.lang ) {
            data.lang = this.lang
          }

          if ( data.next_url ) {
            signed_url = data.next_url
            index++
            handleUpload(index)
          } else {

            if ( this.convertVideo ) {
              data.qualities = this.videoQualities.length === 0 ? [9999] : this.videoQualities
            }

            if ( this.folder) {
              data.folder = this.folder
            }

            let result = !this.superadmin ? await this.ADD_BUSINESS_USER_FILE(data) : await this.ADD_SUPERADMIN_USER_FILE(data)

            if ( !result ) {
              this.uploading = false
              return this.$t('Error')
            }

            this.uploading = false

            this.$emit('success',result)

          }



        };
        xhr.onerror =  () => { this.uploading = false }
        xhr.upload.onprogress = this.progress

        xhr.send(form_data);

      }

      handleUpload(index)

    },
    async fileBrowsed(e) {
      this.file = e.target.files[0]
      await this.upload()
    },
    async drop(e) {
      if ( this.disabled ) {
        return
      }
      this.file = e.dataTransfer.files[0]
      this.upload()

    },
  },
  mounted() {
    this.$emit('set-select-function' , this.selectFile )
    this.uuid = this.$uuid.v4()
  }
}
</script>

<style scoped>

</style>